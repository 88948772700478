import {connect} from "react-redux"
import {NW_DASHBOARD_IS_CONFIRMATION_MODAL} from "../../redux/actions/neurowideDashboardActions"
import trashIcon from "../../assets/icon/trash.svg"

function TeamHeader({NW_DASHBOARD_IS_CONFIRMATION_MODAL, teamID, name}) {

    const deleteTeam = () => {
        NW_DASHBOARD_IS_CONFIRMATION_MODAL({modal: true, id: teamID, action: "delete"})
    }

    return (
        <>
        <div className="SECTION TEAM-HEADER">
            <span>
                <h1>{name}</h1>
                <img src={trashIcon} alt="" onClick={deleteTeam}/>
            </span>
        </div>
        </>
    )
}

export default connect(null, {NW_DASHBOARD_IS_CONFIRMATION_MODAL})(TeamHeader)