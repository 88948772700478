import {useEffect} from "react"
import {useTranslation} from "react-i18next"
import {connect} from "react-redux"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import useTeams from "../../swrCustomHooks/useTeams"
import {IS_MODAL} from "../../redux/actions/neurowideDashboardActions"
import TeamCard from "../../components/team-card/TeamCard"
import LoadingComponent from "../../components/loading-component/LoadingComponent"
import openRedFolderIcon from "../../assets/icon/open-red-folder.svg"
import plusIcon from "../../assets/icon/plus.svg"
import {sessionSelector} from "../../redux/selectors/sessionSelector"

function NeurowideDashboardContent({sessionState, IS_MODAL, isRender}) {
    const {t} = useTranslation()
    const {teams, mutateTeams, isLoadingTeams, isErrorTeams} = useTeams(sessionState.idToken)

    useEffect(() => {
        currentAuthSession().then((response) => {
            REFRESH_COGNITO_TOKEN(response.idToken.toString(), response.idToken.payload.id_user, response.idToken.payload.id_team ? response.idToken.payload.id_team : undefined, response.idToken.payload.role)
        })
        mutateTeams()
    }, [isRender])

    if (isErrorTeams || isLoadingTeams || teams.message) return <LoadingComponent/>
    return (
        <>
        <div className="SECTION NEUROWIDE-DASHBOARD-CONTENT">
            <h2><img src={openRedFolderIcon} alt=""/>{t("LICENCES")}</h2>
            <button className="add-team-btn" onClick={() => IS_MODAL(true)}><img src={plusIcon} alt=""/>{t("Add")}</button>
            <div className="teams">
                {teams.map((team, index) => (
                    <TeamCard key={`team-${index}`} team={team}/>
                ))}
            </div>
        </div>
        </>
    )
}

export default connect(sessionSelector, {IS_MODAL})(NeurowideDashboardContent)