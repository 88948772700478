import {useTranslation} from "react-i18next"
import {connect} from "react-redux"
import {projectsSelector} from "../../redux/selectors/projectsSelector"
import AddProjectBtn from "../../components/add-project-btn/AddProjectBtn"
import openRedFolderIcon from "../../assets/icon/open-red-folder.svg"

function ProjectsHeader({projectsState}) {
    const {t} = useTranslation()
    let title = projectsState.tab.replace("-", " ")
    title = title[0].toUpperCase() + title.slice(1)
    return (
        <>
        <div className="SECTION PROJECTS-HEADER">
            <AddProjectBtn/>
            <h2><img src={openRedFolderIcon} alt=""/>{t(`${title}`)}</h2>
            {title === "My projects" ?
                <><p>{t("Pick up where you left off.")}</p></> :
                title === "Shared projects" ?
                <><p>{t("Contribute to your team's projects.")}</p></> :
                <><p>{t("Restore your deleted projects.")}</p></>
            }
        </div>
        </>
    )
}

export default connect(projectsSelector)(ProjectsHeader)