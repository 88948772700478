import UpdateProjectsForm from "../../forms/projects/UpdateProjectsForm"

export default function WorkflowSettings({setWorkflowNavigation, setIsPulling}) {
    return (
        <>
        <div className="SECTION WORKFLOW-SETTINGS">
            <UpdateProjectsForm setWorkflowNavigation={setWorkflowNavigation} setIsPulling={setIsPulling}/>
        </div>
        </>
    )
}