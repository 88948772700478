import axios from "axios"
import useSWR from "swr"
import {API_URL} from "../constants/apiUrls"

export default function useTeamLexicon(tokenID, teamLexiconID) {

    const fetcher = (args) =>
        axios.get(args.url, {headers: {Authorization: `Bearer ${args.token}`}})
        .then((response) => {
            return response.data
        })

    const {data, mutate, error} = useSWR(teamLexiconID ? {url: `${API_URL}/lexicons/${teamLexiconID}/items`, token: tokenID} : null, fetcher)

    return {
        teamLexicon: data,
        mutateTeamLexicon: mutate,
        isLoadingTeamLexicon: !error && !data,
        isErrorTeamLexicon: error
    }
}