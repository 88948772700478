import {useTranslation} from "react-i18next"
import {connect} from "react-redux"
import {IS_MODAL} from "../../redux/actions/lexiconsActions"
import plusIcon from "../../assets/icon/plus.svg"

function AddLexiconBtn({IS_MODAL}) {
    const {t} = useTranslation()
    return (
        <>
        <button className="add-lexicon-btn" onClick={() => IS_MODAL(true, "input")} title={t("Create a lexicon")}><img src={plusIcon} alt=""/>{t("Create a lexicon")}</button>
        </>
    )
}

export default connect(null, {IS_MODAL})(AddLexiconBtn)