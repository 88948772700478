import * as types from "../redux-constants/localConstants"

export const HANDLE_TYPE = (minNavigation) => ({
    type: types.HANDLE_TYPE,
    payload: {
        minNavigation
    }
})

export const SELECT_LANG = (lang) => ({
    type: types.SELECT_LANG,
    payload: {
        lang
    }
})