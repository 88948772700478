import * as types from "../redux-constants/localConstants"

const local = {
    minNavigation: false,
    lang: window.navigator.language
}

export default function LocalReducer(state=local, action) {
    switch(action.type) {
        case types.HANDLE_TYPE:
            return {
                minNavigation: action.payload.minNavigation,
                lang: state.lang,
            }
        case types.SELECT_LANG:
            return {
                minNavigation: state.minNavigation,
                lang: action.payload.lang,
            }
        default:
            return state
    }
}