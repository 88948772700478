import {Navigate} from "react-router-dom"

export default function RequireIsManager(props) {
    let redirect = props.redirect
    let role = props.role
    if (redirect && role === "user") {
        return <Navigate to="/"/>
    } else if (role === "user" || role === undefined) {
        return ""
    }
    return props.children
}