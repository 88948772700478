import {useEffect} from "react"
import {connect} from "react-redux"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import {globalSelector} from "../../redux/selectors/globalSelector"
import {SET_PAGE} from "../../redux/actions/projectsActions"
import useCountProjects from "../../swrCustomHooks/useCountProjects"
import {useTranslation} from "react-i18next"
import arrowLeftBlackIcon from "../../assets/icon/arrow-left-black.svg"
import arrowLeftGrey from "../../assets/icon/arrow-left-grey.svg"
import arrowRightBlackIcon from "../../assets/icon/arrow-right-black.svg"
import arrowRightGrey from "../../assets/icon/arrow-right-grey.svg"

function ProjectsFooter({sessionState, projectsState, SET_PAGE, isRender}) {
    const {t} = useTranslation()
    const {countProjects, mutateCountProjects, isLoadingCountProjects, isErrorCountProjects} = useCountProjects(sessionState.idToken, projectsState.tab)
    const options = []

    try {
        var index = 1
        while (index < (Math.ceil(countProjects / 8) + 1)) {
            options.push({value: index, label: index})
            index++
        }
    } catch {}

    const handlePage = (value) => {
        const selectPage = document.getElementById("selectPage")
        if (value === "-" && projectsState.page > 1) {
            selectPage.value = parseInt(projectsState.page - 1)
            SET_PAGE(parseInt(projectsState.page) - 1)
        } else if (value === "+" && projectsState.page < Math.ceil(countProjects / 8)) {
            selectPage.value = parseInt(projectsState.page + 1)
            SET_PAGE(parseInt(projectsState.page) + 1)
        }
    }

    useEffect(() => {
        currentAuthSession().then((response) => {
            REFRESH_COGNITO_TOKEN(response.idToken.toString(), response.idToken.payload.id_user, response.idToken.payload.id_team ? response.idToken.payload.id_team : undefined, response.idToken.payload.role)
        })
        mutateCountProjects()
    }, [isRender])

    if (isErrorCountProjects || isLoadingCountProjects || countProjects.message || countProjects <= 8) return ""
    return (
        <>
        <div className="SECTION PROJECTS-FOOTER">
            <div className="left-content">
                <select id="selectPage" name="selectPage" onChange={(e) => SET_PAGE(parseInt(e.target.value))}>
                    {options.map((option, index) => {
                        if ((index + 1) === projectsState.page) {
                            return <option selected key={`option-${index}`} value={option.value}>{option.label}</option>
                        } else {
                            return <option key={`option-${index}`} value={option.value}>{option.label}</option>
                        }
                    })}
                </select>
                <p>sur {Math.ceil(countProjects / 8)} page{Math.ceil(countProjects / 8) >= 2 && "s"}</p>
            </div>
            <div className="right-content">
                <img src={(Math.ceil(countProjects / 8) === 1 || projectsState.page === 1) ? arrowLeftGrey : arrowLeftBlackIcon} alt="" onClick={() => handlePage("-")} title={t("Previous page")}/>
                <img src={(Math.ceil(countProjects / 8) === 1 || projectsState.page === Math.ceil(countProjects / 8)) ? arrowRightGrey : arrowRightBlackIcon} alt="" onClick={() => handlePage("+")} title={t("Next page")}/>
            </div>
        </div>
        </>
    )
}

export default connect(globalSelector, {SET_PAGE})(ProjectsFooter)