import {connect} from "react-redux"
import {sessionSelector} from "../../redux/selectors/sessionSelector"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import {useTranslation} from "react-i18next"
import {useForm} from "react-hook-form"
import axios from "axios"
import {API_USERS} from "../../constants/apiUrls"
import plusIcon from "../../assets/icon/plus.svg"
import {toast} from "react-toastify"

function AddTeamUsersForm({sessionState, REFRESH_COGNITO_TOKEN, forceUpdate}) {
    const {t} = useTranslation()
    const {register, reset, handleSubmit, formState: {errors}} = useForm()

    const addTeamUser = (data) => {
        const bodyRequest = {
            email: data.email,
            id_team: sessionState.idTeam,
            firstname: data.email,
            role: "user"
        }
        currentAuthSession().then((response) => {
            REFRESH_COGNITO_TOKEN(response.idToken.toString(), response.idToken.payload.id_user, response.idToken.payload.id_team ? response.idToken.payload.id_team : undefined, response.idToken.payload.role)
        })
        const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
        axios.post(API_USERS, bodyRequest, headersRequest)
        .then(() => {
            forceUpdate()
            reset({email: ""})
            toast.success(t("Member added"))
        })
        .catch((err) => {
            switch (JSON.parse(err.request.response).message) {
                case "Your Team has reached the number of members limit":
                    toast.error(t("You can't add a new creator to your license. Please liaise with the Neurowide sales department."))
                    break
                case "User already in a team":
                    toast.error(t("This user is already part of a team!"))
                    break
                default:
                    toast.error(t("An error has occurred"))
            }
        })
    }

    return (
        <>
        <form className="add-team-users-form" onSubmit={handleSubmit(addTeamUser)}>
            <input type="email" id="email" name="email" placeholder="Email..." {...register("email", {required: true})}/>
            <button title={t("Add a member")}>
                <span>
                    <img src={plusIcon} alt=""/>
                    <p>{t("Add member")}</p>
                </span>
            </button>
        </form>
        </>
    )
}

export default connect(sessionSelector, {REFRESH_COGNITO_TOKEN})(AddTeamUsersForm)