import axios from "axios"
import useSWR from "swr"
import {API_PROJECTS} from "../constants/apiUrls"

export default function useProjectSource(tokenID, projectID) {

    const fetcher = (args) =>
        axios.get(args.url, {headers: {Authorization: `Bearer ${args.token}`}})
        .then((response) => {
            return response.data
        })

    const {data, mutate, error} = useSWR({url: `${API_PROJECTS}/${projectID}/source`, token: tokenID}, fetcher)

    return {
        projectSource: data,
        mutateProjectSource: mutate,
        isLoadingProjectSource: !error && !data,
        isErrorProjectSource: error
    }
}