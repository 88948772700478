import {Navigate} from "react-router-dom"

export default function RequireIsAdmin(props) {
    let redirect = props.redirect
    let role = props.role
    if (redirect && role !== "admin") {
        return <Navigate to="/"/>
    } else if (role !== "admin" || role  === undefined) {
        return ""
    }
    return props.children
}