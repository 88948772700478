import AddTeamForm from "../../forms/teams/AddTeamForm"

import closeIcon from "../../assets/icon/close.svg"

export default function NeurowideDashboardModal({IS_MODAL, forceUpdate}) {
    return (
        <>
        <div className="neurowide-dashboard-modal">
            <h2>Nouvelle licence</h2>
            <img className="close" src={closeIcon} alt="" onClick={() => IS_MODAL(false)}/>
            <AddTeamForm IS_MODAL={IS_MODAL} forceUpdate={forceUpdate}/>
        </div>
        </>
    )
}