import {useState, useEffect, useRef} from "react"
import {useTranslation} from "react-i18next"
import {TagsInput} from "react-tag-input-component"
import axios from "axios"
import {API_LEXICONS} from "../../constants/apiUrls"
import {connect} from "react-redux"
import {sessionSelector} from "../../redux/selectors/sessionSelector"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import arrowDownIcon from "../../assets/icon/arrow-down.svg"
import arrowRightIcon from "../../assets/icon/arrow-right.svg"
import LoadingComponent from "../../components/loading-component/LoadingComponent"

function LexiconAccordion({sessionState, lexiconName, lexiconItems, lexiconID, isDisable}) {
    const {t} = useTranslation()
    const [display, isDisplay] = useState()
    const [selected, setSelected] = useState(lexiconItems)
    const [scrollHeight, setScrollHeight] = useState("0px")
    const conten = useRef("")

    const handleRequest = (data) => {
        setSelected(data)
        currentAuthSession().then((response) => {
            REFRESH_COGNITO_TOKEN(response.idToken.toString(), response.idToken.payload.id_user, response.idToken.payload.id_team ? response.idToken.payload.id_team : undefined, response.idToken.payload.role)
        })
        const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
        axios.put(`${API_LEXICONS}/${lexiconID}/items`, data, headersRequest)
        .catch((error) => {
            console.error("PUT /items: ", error)
        })
    }


    const handleClick = () => {
        setScrollHeight(!display ?  `${conten.current.scrollHeight}px` : "0px")
        isDisplay(!display)
    }

    useEffect(() => {
        setSelected(lexiconItems ? lexiconItems : [])
    }, [lexiconItems])

    if (!lexiconID || !lexiconName || !lexiconItems) return <LoadingComponent/>
    return (
        <>
        <div className={`lexicon-accordion ${isDisable && "team-lexicon-accordion"}`}>
            <div className="header" onClick={handleClick}>
                <h3>{lexiconName}</h3>
                <img src={display ? arrowDownIcon : arrowRightIcon} alt=""/>
            </div>
            <div ref={conten} className={`content ${display && "active"}`} style={{maxHeight: scrollHeight}}>
                {/* <p>question</p> */}
                <TagsInput
                    disabled={isDisable && true}
                    value={selected}
                    onChange={handleRequest}
                    name="word"
                    placeHolder={!isDisable && t("Add a word")}
                />
            </div>
        </div>
        </>
    )
}

export default connect(sessionSelector)(LexiconAccordion)