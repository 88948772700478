import * as types from "../redux-constants/lexiconsConstants"

export const IS_MODAL = (isModal, modalType) => ({
    type: types.IS_MODAL,
    payload: {
        isModal,
        modalType
    }
})

export const LEXICONS_IS_CONFIRMATION_MODAL = (isConfirmationModal) => ({
    type: types.LEXICONS_IS_CONFIRMATION_MODAL,
    payload: {
        isConfirmationModal,
    }
})