import {useTranslation} from "react-i18next"

export default function NeurowideDashboardHeader() {
    const {t} = useTranslation()
    return (
        <>
        <div className="SECTION NEUROWIDE-DASHBOARD-HEADER">
            <h1>{t("Neurowide dashboard")}</h1>
            <p>{t("Manage client licences.")}</p>
        </div>
        </>
    )
}