import {useReducer, useEffect} from "react"
import {connect} from "react-redux"
import {globalSelector} from "../../redux/selectors/globalSelector"
import {IS_MODAL} from "../../redux/actions/neurowideDashboardActions"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import NeurowideDashboardModal from "../../sections/neurowide-dashboard-modal/NeurowideDashboardModal"
import NeurowideDashboardHeader from "../../sections/neurowide-dashboard-header/NeurowideDashboardHeader"
import NeurowideDashboardContent from "../../sections/neurowide-dashboard-content/NeurowideDashboardContent"
import RequireIsAdmin from "../../tools/RequireIsAdmin"

function NeurowideDashboard({sessionState, neurowideDashboardState, REFRESH_COGNITO_TOKEN, IS_MODAL}) {
    const [isRender, forceUpdate] = useReducer(x => x + 1 + 1, 0)

    useEffect(() => {
        currentAuthSession().then((response) => {
            REFRESH_COGNITO_TOKEN(response.idToken.toString(), response.idToken.payload.id_user, response.idToken.payload.id_team ? response.idToken.payload.id_team : undefined, response.idToken.payload.role)
        })
    }, [isRender])

    return (
        <>
        <RequireIsAdmin role={sessionState.role} redirect={true}>
            <div className="VIEW NEUROWIDE-DASHBOARD">
                {neurowideDashboardState.isModal ? <NeurowideDashboardModal IS_MODAL={IS_MODAL} forceUpdate={forceUpdate}/> : ""}
                <NeurowideDashboardHeader/>
                <span className="line"></span>
                <NeurowideDashboardContent isRender={isRender}/>
            </div>
        </RequireIsAdmin>
        </>
    )
}

export default connect(globalSelector, {REFRESH_COGNITO_TOKEN, IS_MODAL})(NeurowideDashboard)