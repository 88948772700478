import axios from "axios"
import useSWR from "swr"
import {API_USERS} from "../constants/apiUrls"

export default function useUser(userID, tokenID) {

    const fetcher = (args) =>
        axios.get(args.url, {headers: {Authorization: `Bearer ${args.token}`}})
        .then((response) => {
            return response.data
        })

    const {data, mutate, error} = useSWR({url: `${API_USERS}/${userID}`, token: tokenID}, fetcher)

    return {
        user: data,
        mutateUser: mutate,
        isLoadingUser: !error && !data,
        isErrorUser: error
    }
}