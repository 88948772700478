import axios from "axios"
import useSWR from "swr"
import {API_URL} from "../constants/apiUrls"

export default function useTeamLexiconID(tokenID, teamID) {

    const fetcher = (args) =>
        axios.get(args.url, {headers: {Authorization: `Bearer ${args.token}`}})
        .then((response) => {
            return response.data
        })

    const {data, mutate, error} = useSWR({url: `${API_URL}/teams/${teamID}`, token: tokenID}, fetcher)

    return {
        teamLexiconID: data && data.team_lexicon.id,
        mutateTeamLexiconID: mutate,
        isLoadingTeamLexiconID: !error && !data,
        isErrorTeamLexiconID: error
    }
}