import {connect} from "react-redux"
import {useState, useEffect} from "react"
import axios from "axios"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import {sessionSelector} from "../../redux/selectors/sessionSelector"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import {API_USERS} from "../../constants/apiUrls"
import userDefaultImg from "../../assets/img/user-default.svg"

function ProfilePicture({sessionState, REFRESH_COGNITO_TOKEN, idUser=sessionState.idUser}) {
    const [profilPicture, setProfilePicture] = useState(userDefaultImg)

    useEffect(() => {
        currentAuthSession().then((response) => {
            REFRESH_COGNITO_TOKEN(response.idToken.toString(), response.idToken.payload.id_user, response.idToken.payload.id_team ? response.idToken.payload.id_team : undefined, response.idToken.payload.role)
        })
        const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}, responseType: "blob"}
        axios.get(`${API_USERS}/${idUser}/picture`, headersRequest)
        .then((response) => {
            setProfilePicture(URL.createObjectURL(response.data))
        })
        .catch((error) => {
            console.error("GET /user/picture: ", error)
        })
    }, [])

    return <img className="profile-picture" src={profilPicture} alt=""/>
}

export default connect(sessionSelector, {REFRESH_COGNITO_TOKEN})(ProfilePicture)