import {useTranslation} from "react-i18next"

export default function ProfileHeader() {
    const {t} = useTranslation()
    return (
        <>
       <div className="SECTION PROFILE-HEADER">
            <h1>{t("Profile")}</h1>
            <p>{t("Find your personal details here.")}</p>
        </div>
        </>
    )
}