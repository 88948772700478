import {useTranslation} from "react-i18next"
export default function ProjectTag({type}) {
    const {t} = useTranslation()
    const types = {
        "upload": t("Initialized"),
        "lexicons": t("Initialized"),
        "transcription": t("Transcription"),
        "translation": t("Translation"),
        "render": t("Render"),
        "archive": t("Finalized"),
        "finished": t("Exported"),
        "delete": t("Deleted"),
        "public": t("Shared"),
        "private": t("Private")
    }

    return (
        <>
        <span className={`project-tag ${type}`}>{types[type]}</span>
        </>
    )
}