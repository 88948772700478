import {useLocation} from "react-router-dom"
import {useEffect} from "react"
import {connect} from "react-redux"
import {globalSelector} from "../redux/selectors/globalSelector"
import RequireAuth from "../tools/RequireAuth"
import Header from "../sections/header/Header"
import MainNavigation from "../sections/main-navigation/MainNavigation"
import PopupOverlay from "./popup-overlay/PopupOverlay"
import LoadingModal from "./loading-modal/LoadingModal"

function Layout({localState, sessionState, children}) {
    let location = useLocation()
    const sso = location.pathname === "/signin-bouygues" && {client: "bouyges", ssoUrl: "/signin-bouygues"}

    useEffect(() => {
        const bodyNode = document.getElementsByTagName("body")[0]
        bodyNode.style.overflow = "scroll"
    }, [location])

    return (
        <>
        <RequireAuth isLogin={sessionState.isLogin} sso={sso}>
            <Header/>
            <MainNavigation/>
            <PopupOverlay/>
            <LoadingModal/>
        </RequireAuth>
        <main className={(sessionState.isLogin && localState.minNavigation) ? "lg" : (sessionState.isLogin && localState.minNavigation === false) ? "xs" : sessionState.isLogin ? "is-login" : "disconnect"}>{children}</main>
        </>
    )
}

export default connect(globalSelector)(Layout)