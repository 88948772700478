import {useState} from "react"
import {useTranslation} from "react-i18next"
import {useDropzone} from "react-dropzone"
import dropzoneImg from "../../assets/img/dropzoneImg.svg"
import taskIcon from "../../assets/icon/task.svg"

export default function Dropzone({setUploadFile, maxSize, maxDuration, transcriptionStatus, fileName, uploadStatus}) {
    const {t} = useTranslation()
    const [fileDuration, setFileDuration] = useState()
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({disabled: transcriptionStatus === "done" && true})

    const setFileInfo = (file) => {
        const video = document.createElement("video")
        video.src = URL.createObjectURL(file)
        video.addEventListener("loadedmetadata", (event) => {
            setFileDuration(event.target.duration)
        })
    }

    const files = acceptedFiles.map((file) => {
        setUploadFile()
        setFileInfo(file)
        if (file.size > maxSize) {
            return (
                <>
                <img src={dropzoneImg} alt=""/>
                <p>{t("File is to large !")}</p>
                <span>{t("Max size 500 MB")}</span>
                </>
            )
        } else if (fileDuration > maxDuration) {
            return (
                <>
                <img src={dropzoneImg} alt=""/>
                <p>{t("Media duration exceeds allowed limit!")}</p>
                <span>{t("Max duration ") + `${maxDuration / 60} minutes`}</span>
                </>
            )
        } else {
            setFileInfo(file)
            setUploadFile(file)
        }
        return (
            <>
            <img src={taskIcon} alt=""/>
            <span>{file.name}</span>
            </>
        )
    })

    return (
        <>
        <section className={transcriptionStatus === "done" ? `${uploadStatus === "done" ? "min-dropzone-disabled"
            : "dropzone-disabled"}` : `${uploadStatus === "done" ? "min-dropzone" : "dropzone"}`} title={t("Select or drag and drop your media")}>
            <div {...getRootProps({className: "content"})}>
                {files[0] ?
                    <>
                    <input {...getInputProps()}/>
                    {files}
                    </>
                :
                    <>
                    <input {...getInputProps()}/>
                    <img src={dropzoneImg} alt=""/>
                    {fileName ?
                        <><p className="filename-title">{fileName}</p></>
                    :
                        <>
                        <p>{t("Upload video or audio")}</p>
                        <span>{t("Max size 500 MB")} | {t("Max duration ") + `${maxDuration / 60} minutes`}</span>
                        </>
                    }
                    </>
                }

            </div>
        </section>
        </>
    )
}