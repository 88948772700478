import {useState, useEffect} from "react"
import {NavLink, Link} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {connect} from "react-redux"
import {globalSelector} from "../../redux/selectors/globalSelector"
import {HANDLE_TYPE} from "../../redux/actions/localActions"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import axios from "axios"
import {API_TEAMS} from "../../constants/apiUrls"
import neurowideStudioLogo from "../../assets/neurowide-studio.svg"
import neurowideStudioMin from "../../assets/neurowide-studio-min.svg"
import doubleArrowLeftIcon from "../../assets/icon/double-arrow-left.svg"
import homeNavIcon from "../../assets/icon/home-nav.svg"
import projectsNavIcon from "../../assets/icon/projects-nav.svg"
import lexiconsNavIcon from "../../assets/icon/lexicons-nav.svg"
import manageAccountIcon from "../../assets/icon/manage-account.svg"
import teamNavIcon from "../../assets/icon/team-nav.svg"
import nolejLogo from "../../assets/img/nolej-logo.svg"
import helpIcon from "../../assets/icon/help.svg"
import creditsNavIcon from "../../assets/icon/credits-nav.svg"
import RequireIsManager from "../../tools/RequireIsManager"
import RequireIsAdmin from "../../tools/RequireIsAdmin"

const getCredits = (idTeam, headersRequest) => {
    return axios.get(`${API_TEAMS}/${idTeam}`, headersRequest)
    .then((response) => {
        return response.data.team.nb_credits
    })
    .catch ((error) => {
        console.error("GET /teams/team: ", error)
    })
}

function MainNavigation({sessionState, localState, HANDLE_TYPE}) {
    const {t} = useTranslation()
    let [minNavigation, setMinNavigation] = useState(localState.minNavigation)
    let [credits, setCredits] = useState()
    const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
    try {
        getCredits(sessionState.idTeam, headersRequest).then((response) => {
            setCredits(response)
        })
    } catch {}

    const handleClick = () => {
        HANDLE_TYPE(!minNavigation)
        setMinNavigation(!minNavigation)
    }

    useEffect(() => {
        currentAuthSession().then((response) => {
            REFRESH_COGNITO_TOKEN(response.idToken.toString(), response.idToken.payload.id_user, response.idToken.payload.id_team ? response.idToken.payload.id_team : undefined, response.idToken.payload.role)
        })
    }, [])

    return (
        <>
        <div className={`SECTION ${minNavigation ? "MIN-NW-LEFT-NAV" : "NW-LEFT-NAV"}`}>
            <img className="nw-logo" src={minNavigation ? neurowideStudioMin : neurowideStudioLogo} alt=""/>
            <img onClick={handleClick} className="nav-action-btn" src={doubleArrowLeftIcon} alt="" title={minNavigation ? t("Expand navigation menu") : t("Collapse navigation menu")}/>
            <span className="line"></span>
            <nav>
                <ul className="top-nav">
                    {sessionState.role !== "admin" &&
                        <>
                        <li title={t("Homepage")}><NavLink to="/" className="navlink"><img src={homeNavIcon} alt=""/><span>{t("Home")}</span></NavLink></li>
                        <li title={t("Projects")}><NavLink to="/projects" className="navlink"><img src={projectsNavIcon} alt=""/><span>{t("Projects")}</span></NavLink></li>
                        <li title={t("Lexicons")}><NavLink to="/lexicons" className="navlink"><img src={lexiconsNavIcon} alt=""/><span>{t("Lexicons")}</span></NavLink></li>
                        <RequireIsManager role={sessionState.role}>
                            <li title={t("Team management")}><NavLink to="/team-management" className="navlink"><img src={teamNavIcon} alt=""/><span>{t("Team management")}</span></NavLink></li>
                        </RequireIsManager>
                        <span className="second-line"></span>
                        <li title={t("Nolej")} className="nolej-btn"><a href="https://live.nolej.io/signin-bouygues/" target="_blank" rel="noreferrer"><img className="nolej-logo" src={nolejLogo} alt=""/></a></li>
                        </>
                    }
                    <RequireIsAdmin role={sessionState.role}>
                        <li><NavLink to="/neurowide-dashboard"><img src={manageAccountIcon} alt=""/><span>{t("Neurowide dashboard")}</span></NavLink></li>
                    </RequireIsAdmin>
                </ul>
                <ul className="bottom-nav">
                    <li className="help" title={t("Help")}><Link to="/help"><img src={helpIcon} alt=""/><span>{t("Help")}</span></Link></li>
                    <li className="credit" title={t("Your credits")}><Link to="/"><img src={creditsNavIcon} alt=""/><span>{`${credits} ${t("credits available")}`}</span></Link></li>
                </ul>
            </nav>
        </div>
        </>
    )
}

export default connect(globalSelector, {HANDLE_TYPE})(MainNavigation)