import axios from "axios"
import useSWR from "swr"
import {API_PROJECTS} from "../constants/apiUrls"

export default function useProject(tokenID, projectID) {

    const fetcher = (args) =>
    axios.get(args.url, {headers: {Authorization: `Bearer ${args.token}`}})
        .then((response) => {
            return response.data
        })

    const {data, mutate, error} = useSWR({url: `${API_PROJECTS}/${projectID}`, token: tokenID}, fetcher)

    return {
        project: data,
        mutateProject: mutate,
        isLoadingProject: !error && !data,
        isErrorProject: error
    }
}