import {useEffect} from "react"
import {useTranslation} from "react-i18next"
import {connect} from "react-redux"
import {IS_MODAL} from "../../redux/actions/lexiconsActions"
import AddLexiconsModalForm from "../../forms/lexicons/AddLexiconsModalForm"
import SelectlexiconsmodalForm from "../../forms/lexicons/SelectLexiconsModalForm"
import closeIcon from "../../assets/icon/close.svg"

function LexiconsModal({IS_MODAL, type, forceUpdate}) {
    const {t} = useTranslation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
        <div className="_SECTION LEXICONS-MODAL">
            <h3>{t("Select a lexicon")}</h3>
            <img className="close" src={closeIcon} alt="" onClick={() => IS_MODAL(false)}/>
            {type === "input" ? <AddLexiconsModalForm IS_MODAL={IS_MODAL} forceUpdate={forceUpdate}/> : <SelectlexiconsmodalForm IS_MODAL={IS_MODAL} forceUpdate={forceUpdate}/>}
        </div>
        </>
    )
}

export default connect(null, {IS_MODAL})(LexiconsModal)