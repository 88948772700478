import {useTranslation} from "react-i18next"

export default function ProgressBar({project, step, handleClickNavigation}) {
    const {t} = useTranslation()
    let selectedLanguage = useTranslation().i18n.language
    if (selectedLanguage === "fr") {
        selectedLanguage = "fr-FR"
    }
    const progressLevel = {
        upload: selectedLanguage === "fr-FR" ? 5 : 2.5,
        lexicons: selectedLanguage === "fr-FR" ? 28.5 : 25,
        lexiconsDone: selectedLanguage === "fr-FR" ? 28.5 : 25,
        transcription: 50,
        translation: selectedLanguage === "fr-FR" ? 72 : 74,
        render: 100,
        archive: 100,
    }
    return (
        <>
        <div className="progress-bar">
            <div className="line-1" style={{width: `${progressLevel[step]}%`}}>
                <div className="point"></div>
            </div>
            <div className="line-2"></div>
            <div className="state">
                <p className="color-primary" onClick={() => handleClickNavigation(1)} title={t("Settings")}>1. {t("Settings")}</p>
                <p className="color-primary" onClick={() => handleClickNavigation(2)} title={t("Lexicons")}>2. {t("Lexicons")}</p>
                <p className={(project.transcription_status === "done" && "color-primary") ||
                    (project.translation_status === "done" && "color-primary") ||
                    (project.render_status === "render" && "color-primary")} onClick={() => handleClickNavigation(3)} title={t("Transcription")}>3. {t("Transcription")}</p>
                <p className={(project.translation_status === "done" && "color-primary") ||
                    (project.render_status === "render" && "color-primary")} onClick={() => handleClickNavigation(4)} title={t("Translation")}>4. {t("Translation")}</p>
                <p className={project.render_status === "done" && "color-primary"} onClick={() => handleClickNavigation(5)} title={t("Preview")}>5. {t("Preview")}</p>
            </div>
        </div>
        </>
    )
}