import {useTranslation} from "react-i18next"
import ProfileForm from "../../forms/profile/ProfileForm"
import ProfilePicture from "../../components/profile-picture/ProfilePicture"

export default function ProfileContent() {
    const {t} = useTranslation()
    return (
        <>
        <div className="SECTION PROFILE-CONTENT">
            <h2>{t("Personal information")}</h2>
            <ProfileForm/>
            <div className="right-content">
                <ProfilePicture/>
            </div>
        </div>
        </>
    )
}