import axios from "axios"
import useSWR from "swr"
import {API_TEAMS} from "../constants/apiUrls"

export default function useTeamUsers(idTeam, tokenID) {

    const fetcher = (args) =>
        axios.get(args.url, {headers: {Authorization: `Bearer ${args.token}`}})
        .then((response) => {
            return response.data.users
        })

    const {data, mutate, error} = useSWR({url: `${API_TEAMS}/${idTeam}`, token: tokenID}, fetcher)

    return {
        teamUsers: data.users,
        mutateUsers: mutate,
        isLoadingTeamUsers: !error && !data,
        isErrorTeamUsers: error
    }
}