import * as types from "../redux-constants/sessionConstants"

export const LOGIN = (idToken, idUser, idTeam, role) => ({
    type: types.LOGIN,
    payload: {
        isLogin: true,
        idToken,
        idUser,
        idTeam,
        role
    }
})

export const REFRESH_COGNITO_TOKEN = (idToken, idUser, idTeam, role) => ({
    type: types.REFRESH_COGNITO_TOKEN,
    payload: {
        isLogin: true,
        idToken,
        idUser,
        idTeam,
        role
    }
})

export const LOGOUT = () => ({
    type: types.LOGOUT,
    payload: {
        isLogin: false
    }
})