import {useTranslation} from "react-i18next"
import {connect} from "react-redux"
import {IS_MODAL} from "../../redux/actions/lexiconsActions"

function SelectLexiconsBtn({IS_MODAL}) {
    const {t} = useTranslation()
    return (
        <>
        <button className="select-lexicon-btn" onClick={() => IS_MODAL(true, "select")} title={t("Select a lexicon")}>{t("Select a lexicon")}</button>
        </>
    )
}

export default connect(null, {IS_MODAL})(SelectLexiconsBtn)