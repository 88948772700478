import {useTranslation} from "react-i18next"

export default function LexiconsHeader() {
    const {t} = useTranslation()
    return (
        <>
        <div className="SECTION LEXICONS-HEADER">
            <h1>{t("Lexicons management")}</h1>
            <p>{t("Enrich your team's lexical field by adding your own lexicons.")}</p>
        </div>
        </>
    )
}