import {useState, useRef} from "react"
import arrowRightBlackIcon from "../../assets/icon/arrow-right-black.svg"
import arrowDownBlackIcon from "../../assets/icon/arrow-down-black.svg"

export default function HelpAccordion({question}) {
    const [display, isDisplay] = useState(false)
    const [scrollHeight, setScrollHeight] = useState("0px")
    const conten = useRef("")

    const handleClick = () => {
        setScrollHeight(!display ?  `${conten.current.scrollHeight}px` : "0px")
        isDisplay(!display)
    }

    return (
        <>
        <div className="help-accordion">
            <div className="header" onClick={handleClick}>
                <h3>{question.question}</h3>
                <img src={display ? arrowDownBlackIcon : arrowRightBlackIcon} alt=""/>
            </div>
            <div ref={conten} className={`content ${display && "active"}`} style={{maxHeight: scrollHeight}}>
                <p>{question.answer}</p>
            </div>
        </div>
        </>
    )
}