import * as types from "../redux-constants/teamsConstants"

const teams = {
    isConfirmationModal: {modal: false, id: null, action: null},
}

export default function TeamsReducer(state=teams, action) {
    switch(action.type) {
        case types.TEAMS_IS_CONFIRMATION_MODAL:
            return {
                isConfirmationModal: action.payload.isConfirmationModal,
            }
        default:
            return state
    }
}