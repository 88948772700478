import {createRoot} from "react-dom/client"
import configureStore from "./redux/app/store"
import {Provider} from "react-redux"
import {PersistGate} from "redux-persist/integration/react"
import {Amplify} from "aws-amplify"
import AWS_CONFIG from "./aws/config"
import "./i18n/i18n"
import App from "./App"
import DisableConsoleLog from "./tools/DisableConsoleLog"

DisableConsoleLog()
Amplify.configure(AWS_CONFIG)
const {store, persistor} = configureStore()

createRoot(document.getElementById("root")).render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
        <App/>
        </PersistGate>
    </Provider>
)