import * as types from "../redux-constants/sessionConstants"

const session = {
    isLogin: false,
    idToken: undefined,
    idUser: undefined,
    idTeam: undefined,
    role: undefined,
}

export default function SessionReducer(state=session, action) {
    switch(action.type) {
        case types.LOGIN:
            return {
                isLogin: action.payload.isLogin,
                idToken: action.payload.idToken,
                idUser: action.payload.idUser,
                idTeam: action.payload.idTeam,
                role: action.payload.role,
            }
        case types.REFRESH_COGNITO_TOKEN:
            return {
                isLogin: action.payload.isLogin,
                idToken: action.payload.idToken,
                idUser: action.payload.idUser,
                idTeam: action.payload.idTeam,
                role: action.payload.role,
            }
        case types.LOGOUT:
            return {
                isLogin: action.payload.isLogin
            }
        default:
            return state
    }
}