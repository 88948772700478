import UpdateTeamForm from "../../forms/teams/UpdateTeamForm"

export default function TeamContent({team, forceUpdate}) {
    return (
        <>
        <div className="SECTION TEAM-CONTENT">
            <UpdateTeamForm team={team} forceUpdate={forceUpdate}/>
        </div>
        </>
    )
}