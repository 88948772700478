import teamDefaultImg from "../../assets/img/team-default.svg"

export default function TeamCard({team}) {
    return (
        <>
        <a href={`/neurowide-dashboard/team/${team.id}`} className="team-card">
            <img src={teamDefaultImg} alt=""/>
            <p>{team.name}</p>
        </a>
        </>
    )
}