const AWS_CONFIG = {
    aws_project_region: process.env.AWS_PROJECT_REGION,
    aws_cognito_region: process.env.AWS_COGNITO_REGION,
    aws_user_pools_id: process.env.AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: process.env.AWS_USER_POOLS_WEB_CLIENT_ID,
    oauth: {
        domain: process.env.OAUTH_DOMAIN,
        scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
        redirectSignIn: process.env.OAUTH_REDIRECT_SIGN_IN,
        redirectSignOut: process.env.OAUTH_REDIRECT_SIGN_OUT,
        responseType: "code"
    },
}

export default AWS_CONFIG