import * as types from "../redux-constants/workflowConstants"

const worfklow = {
    isConfirmationModal: {modal: false, id: null},
}

export default function WorkflowReducer(state=worfklow, action) {
    switch(action.type) {
        case types.WORKFLOW_IS_CONFIRMATION_MODAL:
            return {
                isConfirmationModal: action.payload.isConfirmationModal,
            }
        default:
            return state
    }
}