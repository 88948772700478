import {useTranslation} from "react-i18next"

export default function HelpHeader() {
    const {t} = useTranslation()

    return (
        <>
        <div className="SECTION HELP-HEADER">
            <h1>{t("Help")}</h1>
            {/* <p>{t("Hello, what will you be working on today?")}</p> */}
        </div>
        </>
    )
}