import {useTranslation} from "react-i18next"
import {connect} from "react-redux"
import {IS_MODAL} from "../../redux/actions/projectsActions"
import plusIcon from "../../assets/icon/plus.svg"

function AddProjectBtn({IS_MODAL}) {
    const {t} = useTranslation()
    return (
        <>
        <button className="add-project-btn" onClick={() => IS_MODAL(true)} title={t("Create a project")}><img src={plusIcon} alt=""/>{t("Create project")}</button>
        </>
    )
}

export default connect(null, {IS_MODAL})(AddProjectBtn)