import * as types from "../redux-constants/lexiconsConstants"

const lexicons = {
    isModal: false,
    isConfirmationModal: {modal: false, id: null, action: null},
    modalType: undefined,
}

export default function LexiconsReducer(state=lexicons, action) {
    switch(action.type) {
        case types.IS_MODAL:
            return {
                isModal: action.payload.isModal,
                modalType: action.payload.modalType,
                isConfirmationModal: state.isConfirmationModal,
            }
        case types.LEXICONS_IS_CONFIRMATION_MODAL:
            return {
                isModal: state.isModal,
                isConfirmationModal: action.payload.isConfirmationModal,
                tab: state.tab,
                projectID: state.projectID,
            }
        default:
            return state
    }
}