import HelpHeader from "../sections/help-header/HelpHeader"
import HelpQuestions from "../sections/help-questions/HelpQuestions"

export default function Help() {
    return (
        <>
        <div className="VIEW HELP">
            <HelpHeader/>
            <span className="line"></span>
            <HelpQuestions/>
        </div>
        </>
    )
}