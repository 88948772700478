import {useTranslation} from "react-i18next"
import ProgressBar from "../../components/progress-bar/ProgressBar"
import arrowLeftIcon from "../../assets/icon/arrow-left.svg"
import arrowRightIcon from "../../assets/icon/arrow-right.svg"

export default function WorkflowHeader({project, step, workflowNavigation, setWorkflowNavigation, maxStep}) {
    const {t} = useTranslation()

    const handleClickNavigation = (index) => {
        (workflowNavigation <= maxStep && index <= maxStep) && setWorkflowNavigation(index)
    }

    const handleClick = (value) => {
        (value === "+" && workflowNavigation < maxStep) ? setWorkflowNavigation(workflowNavigation + 1) : (value === "-" && workflowNavigation > 1) && setWorkflowNavigation(workflowNavigation - 1)
    }

    return (
        <>
        <div className="SECTION WORKFLOW-HEADER">
                <h1>{project.title}</h1>
                <p>{t("Follow the workflow steps and export your work.")}</p>
                <ProgressBar project={project} step={step} handleClickNavigation={handleClickNavigation}/>
                {!project.finished &&
                    <>
                    <nav>
                        <button className="previous-btn" onClick={() => handleClick("-")} title={t("Previous step")}><img src={arrowLeftIcon} alt=""/></button>
                        <button className="next-btn" onClick={() => handleClick("+")} title={t("Next step")}><img src={arrowRightIcon} alt=""/></button>
                    </nav>
                    </>
                }
        </div>
        </>
    )
}