import LoadingModal from "../../components/loading-modal/LoadingModal"
import WorkflowSettings from "../workflow-settings/WorkflowSettings"
import WorkflowLexicons01 from "../workflow-lexicons-01/WorkflowLexicons01"
import WorkflowLexicons02 from "../workflow-lexicons-02/WorkflowLexicons02"
import WorkflowTranscription from "../workflow-transcription/WorkflowTransciption"
import WorkflowTranslation from "../workflow-translation/WorkflowTranslation"
import WorkflowRender from "../workflow-render/WorkflowRender"

const Switch = (props) => {
    const {value, children} = props
    return children.find(child => {
        return child.props.value === value
    })
}

export default function WorkflowContent({params}) {
    const steps = {
        1: "upload",
        2: params.project.lexicon_status === "done" ? "lexiconsDone" : "lexicons",
        3: "transcription",
        4: "translation",
        5: "render",
        6: "archive",
    }
    let trie = params.tries[0]
    let statusCode = trie.status_code
    let step = trie.step

    if (params.project.finished) {
        params.setWorkflowNavigation(5)
    } else {
        switch (step) {
            case "upload":
                if (statusCode === "done") {
                    step = "lexicons"
                    params.workflowNavigation === -1 && params.setWorkflowNavigation(2)
                } else {
                    params.workflowNavigation === -1 && params.setWorkflowNavigation(1)
                }
                break
            case "lexicons":
                params.workflowNavigation === -1 && params.setWorkflowNavigation(2)
                break
            case "lexiconsDone":
                params.workflowNavigation === -1 && params.setWorkflowNavigation(2)
                break
            case "transcription":
                (statusCode === "in_progress" && params.workflowNavigation === -1) ? params.setWorkflowNavigation(2) :
                params.workflowNavigation === -1 && params.setWorkflowNavigation(3)
                break
            case "translation":
                (statusCode === "in_progress" && params.workflowNavigation === -1) ? params.setWorkflowNavigation(3) :
                params.workflowNavigation === -1 && params.setWorkflowNavigation(4)
                break
            case "render":
                (statusCode === "in_progress" && params.workflowNavigation === -1) ? params.setWorkflowNavigation(4) :
                params.workflowNavigation === -1 && params.setWorkflowNavigation(5)
                break
            case "archive":
                params.workflowNavigation === -1 && params.setWorkflowNavigation(5)
                break
            default:
                break
        }
    }

    step === "archive" && params.setIsUpdateWorkflowNavigation(false)
    if (params.workflowNavigation === -1 && statusCode === "in_progress") {
        params.setIsPulling(true)
    }
    if (params.project.archive_status === "done" || params.project.render_status === "in_progress") {
        params.setMaxStep(5)
    } else if (params.project.render_status === "done" || params.project.render_status === "in_progress") {
        params.setMaxStep(5)
    } else if (params.project.translation_status === "done") {
        params.setMaxStep(4)
    } else if (params.project.transcription_status === "done") {
        params.setMaxStep(3)
    } else if (params.project.lexicon_status === "done" || params.project.lexicon_status === "skip") {
        params.setMaxStep(2)
    } else if (params.project.upload_status === "done") {
        params.setMaxStep(2)
    }

    step = steps[params.workflowNavigation]
    params.setWorkflowStep(step)
    return (
        <>
        <LoadingModal display={params.isPulling} trie={trie}/>
        <Switch value={step}>
            <WorkflowSettings value={"upload"} setIsPulling={params.setIsPulling} setWorkflowNavigation={params.setWorkflowNavigation} setMaxStep={params.setMaxStep}/>
            <WorkflowLexicons01 value={"lexicons"} setIsPulling={params.setIsPulling}/>
            <WorkflowLexicons02 value={"lexiconsDone"} setIsPulling={params.setIsPulling} forceUpdate={params.forceUpdate}/>
            <WorkflowTranscription value={"transcription"} setIsPulling={params.setIsPulling} langCode={params.project.lang_code}/>
            <WorkflowTranslation value={"translation"} setIsPulling={params.setIsPulling} langCode={params.project.lang_code}/>
            <WorkflowRender value={"archive"} project={params.project}/>
            <WorkflowRender value={"render"} project={params.project} exportConfirmation={params.exportConfirmation} setExportConfirmation={params.setExportConfirmation}/>
        </Switch>
        </>
    )
}