import {useTranslation} from "react-i18next"

export default function HomeHeader() {
    const {t} = useTranslation()

    return (
        <>
        <div className="SECTION HOME-HEADER">
            <h1>{t("Welcome to Neurowide Studio")}</h1>
            <p>{t("Hello, what will you be working on today?")}</p>
        </div>
        </>
    )
}