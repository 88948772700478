import axios from "axios"
import useSWR from "swr"
import {API_PROJECTS} from "../constants/apiUrls"

export default function useTries(tokenID, projectID) {

    const fetcher = (args) =>
        axios.get(args.url, {headers: {Authorization: `Bearer ${args.token}`}})
        .then((response) => {
            return response.data
        })

    const {data, mutate, error} = useSWR({url: `${API_PROJECTS}/${projectID}/tries`, token: tokenID}, fetcher)

    return {
        tries: data,
        mutateTries: mutate,
        isLoadingTries: !error && !data,
        isErrorTries: error
    }
}