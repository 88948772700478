import {useState, useEffect} from "react"
import {connect} from "react-redux"
import {globalSelector} from "../../redux/selectors/globalSelector"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import axios from "axios"
import {API_PROJECTS} from "../../constants/apiUrls"
import SubPlayer from "../../SubPlayer/SubPlayer"
import LoadingComponent from "../../components/loading-component/LoadingComponent"
import {useTranslation} from "react-i18next"
import {toast} from "react-toastify"

function WorkflowTranslation({sessionState, projectsState, REFRESH_COGNITO_TOKEN, setIsPulling, langCode}) {
    const {t} = useTranslation()
    const [mediaUrl, setMediaUrl] = useState()
    const [audioUrl, setAudioUrl] = useState()
    const [srtFile, setSrtFile] = useState()
    const [latestSrt, setLatestSrt] = useState()

    const refreshToken = () => currentAuthSession().then((response) => {
        REFRESH_COGNITO_TOKEN(response.idToken.toString(), response.idToken.payload.id_user, response.idToken.payload.id_team ? response.idToken.payload.id_team : undefined, response.idToken.payload.role)
    })

    const refreshMedia = (loadAudio) => {
        const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
        const getVideo = () => axios.get(`${API_PROJECTS}/${projectsState.projectID}/source`, headersRequest, {maxRedirects: 0})
        const getAudio = () => axios.get(`${API_PROJECTS}/${projectsState.projectID}/audio`, { responseType: 'blob', ...headersRequest }, {maxRedirects: 0}).catch(() => Promise.resolve());
        return Promise.all([getVideo(), loadAudio ? getAudio() : Promise.resolve()])
            .then(([video, audio]) => {
                setMediaUrl(video.data)
                if (audio) setAudioUrl(URL.createObjectURL(audio.data))
                setTimeout(() => {
                    refreshToken().then(() => refreshMedia())
                }, 5 * 60 * 1000)
            })
    }

    const save = () => {
        const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
        axios.put(`${API_PROJECTS}/${projectsState.projectID}/translations/${langCode === "fr" ? "en" : "fr"}`, latestSrt, headersRequest)
        .then(() => {
            toast.success(t("Subtitles saved!"))
        })
        .catch((err) => {
            toast.error(t("An error has occurred"))
            console.error("error :", err)
        })
    }

    const burn = () => {
        const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
        axios.put(`${API_PROJECTS}/${projectsState.projectID}/translations/${langCode === "fr" ? "en" : "fr"}`, latestSrt, headersRequest)
        .then(() => {
            axios.post(`${API_PROJECTS}/${projectsState.projectID}/tries`, {step: "render"}, headersRequest)
            .then(() => {
                setIsPulling(true)
            })
            .catch((error) => {
                console.error("POST /projects/project/tries: ", error)
            })
        })
        .catch((error) => {
            console.error("PUT /projects/project/translations/langCode: ", error)
        })
    }

    useEffect(() => {
        refreshMedia(true)
        .then(() => {
            const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
            axios.get(`${API_PROJECTS}/${projectsState.projectID}/translations/${langCode === "fr" ? "en" : "fr"}`, headersRequest)
            .then((response) => {
                setSrtFile(response.data)
            })
            .catch((error) => {
                console.error("GET /projects/project/translations/langCode: ", error)
            })
        })
        .catch((error) => {
            console.error("refreshMedia: ", error)
        })
    }, [])

    if (!setMediaUrl || !srtFile) return <LoadingComponent/>
    return (
        <>
        <div className="_SECTION WORKFLOW-TRANSLATION">
            <SubPlayer mediaUrl={mediaUrl} audioUrl={audioUrl} srtFile={srtFile} setLatestSrt={setLatestSrt} save={save}/>
            <div className="actions">
                <button onClick={burn} title={t("Finalize")}>{t("Finalize")}</button>
            </div>
        </div>
        </>
    )
}

export default connect(globalSelector, {REFRESH_COGNITO_TOKEN})(WorkflowTranslation)
