import {createStore, combineReducers} from "redux"
import {persistStore, persistReducer} from "redux-persist"
import storage from "redux-persist/lib/storage"
import LexiconsReducer from "../reducers/lexiconsReducer"
import LocalReducer from "../reducers/localReducer"
import NeurowideDashboardReducer from "../reducers/neurowideDashboardReducer"
import ProjectsReducer from "../reducers/projectsReducer"
import SessionReducer from "../reducers/sessionReducer"
import Teamsreducer from "../reducers/teamsReducer"
import WorkflowReducer from "../reducers/workflowRecucer"

export default function configureStore() {

    const rootReducer = combineReducers({
        lexiconsState: LexiconsReducer,
        localState: LocalReducer,
        neurowideDashboardState: NeurowideDashboardReducer,
        projectsState: ProjectsReducer,
        sessionState: SessionReducer,
        teamsState: Teamsreducer,
        workflowState: WorkflowReducer,
    })

    const persistConfig = {
        key: "root",
        storage,
    }

    const persistedReducer = persistReducer(persistConfig, rootReducer)

    let store = createStore(persistedReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
    let persistor = persistStore(store)

    return {store, persistor}
}