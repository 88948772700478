import {signIn, confirmSignIn, fetchAuthSession} from "aws-amplify/auth"

export default async function firstLogin({email, oldPassword, newPassword}) {
    let {isSignedIn, nextStep} = await signIn({username: email, password: oldPassword})

    switch (nextStep.signInStep) {
        case "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE":
            await confirmSignIn({challengeResponse: "answer"})
            break
        case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED":
            await confirmSignIn({challengeResponse: newPassword})
            break
        case "DONE":
            break
        default:
            break
    }

    try {
        const {accessToken, idToken} = (await fetchAuthSession()).tokens ?? {}
        return {accessToken, idToken}
    } catch (error) {
        console.error("firstLogin: ", error)
    }
}