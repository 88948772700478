import {connect} from "react-redux"
import {globalSelector} from "../../redux/selectors/globalSelector"
import axios from "axios"
import {API_PROJECTS} from "../../constants/apiUrls"
import {useTranslation} from "react-i18next"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import LexiconAccordion from "../../components/lexicon-accordion/LexiconAccordion"
import useTeamLexiconID from "../../swrCustomHooks/useTeamLexiconID"
import useTeamLexicon from "../../swrCustomHooks/useTeamLexicon"
import useProjectLexicon from "../../swrCustomHooks/useProjectLexicon"
import useProjectLexiconItems from "../../swrCustomHooks/useProjectLexiconItems"
import LoadingComponent from "../../components/loading-component/LoadingComponent"
import {toast} from "react-toastify"

function WorkflowLexicons02({sessionState, projectsState, REFRESH_COGNITO_TOKEN, setIsPulling}) {
    const {t} = useTranslation()
    const {teamLexiconID, isLoadingTeamLexiconID, isErrorTeamLexiconID} = useTeamLexiconID(sessionState.idToken, sessionState.idTeam)
    const {teamLexicon, isLoadingTeamLexicon, isErrorTeamLexicon} = useTeamLexicon(sessionState.idToken, typeof teamLexiconID === "object" ? teamLexiconID.team_lexicon.id : teamLexiconID)
    const {projectLexicon, isLoadingProjectLexicon, isErrorProjectLexicon} = useProjectLexicon(sessionState.idToken, projectsState.projectID)
    const projectLexiconID = projectLexicon && projectLexicon.id
    const {projectLexiconItems, isLoadingProjectLexiconItems, isErrorProjectLexiconItems} = useProjectLexiconItems(sessionState.idToken, projectLexiconID)

    const transcription = () => {
        currentAuthSession().then((response) => {
            REFRESH_COGNITO_TOKEN(response.idToken.toString(), response.idToken.payload.id_user, response.idToken.payload.id_team ? response.idToken.payload.id_team : undefined, response.idToken.payload.role)
        })
        const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
        axios.post(`${API_PROJECTS}/${projectsState.projectID}/tries`, {step: "transcription"}, headersRequest)
        .then((response) => {
            if (response.data.message === `maximun number of tries for "transcription" has been reached or exceeded`) {
                toast.warning(t("The maximum number of transcriptions has been reached or exceeded!"))
            }
            setIsPulling(true)
        })
        .catch((error) => {
            console.error("POST /projects/project/tries: ", error)
        })
    }

    if (isErrorTeamLexiconID ||
        isErrorTeamLexicon ||
        isErrorProjectLexicon ||
        isErrorProjectLexiconItems ||
        isLoadingTeamLexiconID ||
        isLoadingTeamLexicon ||
        isLoadingProjectLexicon ||
        isLoadingProjectLexiconItems ||
        teamLexiconID.message ||
        teamLexicon.message ||
        projectLexicon.message ||
        projectLexiconItems.message) return <LoadingComponent/>
    return (
        <>
        <div className="SECTION WORKFLOW-LEXICON-02">
            <h2>{t("PROJECT LEXICONS")}</h2>
            <div className="lexicons">
                <LexiconAccordion lexiconName={t("Team Lexicon")} lexiconItems={teamLexicon} lexiconID={teamLexiconID} isDisable={true}/>
                <LexiconAccordion lexiconName={projectLexicon.name} lexiconItems={projectLexiconItems} lexiconID={projectLexicon.id}/>
                <button className="btn" onClick={transcription} title={t("Transcribe")}>{t("Transcribe")}</button>
            </div>
        </div>
        </>
    )
}

export default connect(globalSelector, {REFRESH_COGNITO_TOKEN})(WorkflowLexicons02)