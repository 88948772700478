import {connect} from "react-redux"
import {globalSelector} from "../../redux/selectors/globalSelector"
import {useTranslation} from "react-i18next"
import {useLocation} from "react-router-dom"

function PopupOverlay({lexiconsState, neurowideDashboardState, projectsState, teamsState, workflowState}) {
    const {t} = useTranslation()
    let location = useLocation()
    const locationPathname = location.pathname.split("/")[1]
    return (
        <>
        {((lexiconsState.isModal && (locationPathname === "lexicons" || locationPathname === "workflow"))
        || ((neurowideDashboardState.isConfirmationModal.modal || neurowideDashboardState.isModal) && locationPathname === "neurowide-dashboard")
        || (projectsState.isModal && (location.pathname === "/" || locationPathname === "projects"))
        || (projectsState.isConfirmationModal.modal && (location.pathname === "/" || locationPathname === "projects"))
        || (lexiconsState.isConfirmationModal.modal && locationPathname === "lexicons")
        || (teamsState.isConfirmationModal.modal && locationPathname === "team-management")
        || (workflowState.isConfirmationModal.modal && locationPathname === "workflow")) &&
            <><div className="popup-overlay force-display"></div></>
        }
        <div className="screen-limit-overlay">
            <h2>{t("Neurowide.studio requires a larger screen, please use a desktop browser.")}</h2>
        </div>
        </>
    )
}

export default connect(globalSelector)(PopupOverlay)