import {useEffect, useCallback, useState} from "react"
import DT from "duration-time-conversion"
import {useTranslation} from "react-i18next"

function findIndex(subs, startTime) {
    return subs.findIndex((item, index) => {
        return (
            (startTime >= item.endTime && !subs[index + 1]) ||
            (item.startTime <= startTime && item.endTime > startTime) ||
            (startTime >= item.endTime && subs[index + 1] && startTime < subs[index + 1].startTime)
        )
    })
}

export default function Component({render, bounds, subtitle, newSub, addSub, player, playing}) {
    const {t} = useTranslation()
    const [isDroging, setIsDroging] = useState(false)
    const [drogStartTime, setDrogStartTime] = useState(0)
    const [drogEndTime, setDrogEndTime] = useState(0)

    const getEventTime = useCallback(
        (event) => {
			const offsetTime = ((event.pageX - bounds.x) / bounds.width) * render.duration
			return render.beginTime + offsetTime
        },
        [render, bounds],
    )

    const onMouseDown = useCallback(
        (event) => {
            if (event.button !== 0) return
            const clickTime = getEventTime(event)
            setIsDroging(true)
            setDrogStartTime(clickTime)
        },
        [getEventTime],
    )

    const onMouseMove = useCallback(
        (event) => {
            if (isDroging) {
                if (playing) player.pause()
                setDrogEndTime(getEventTime(event))
            }
        },
        [isDroging, playing, player, getEventTime],
    )

    const onDocumentMouseUp = useCallback(() => {
        if (isDroging) {
            if (drogStartTime > 0 && drogEndTime > 0 && drogEndTime - drogStartTime >= 0.2) {
                const index = findIndex(subtitle, drogStartTime) + 1
				const startTime = Math.max(subtitle[index - 1]?.endTime || 0, drogStartTime)
				const endTime = Math.min(subtitle[index]?.startTime || player.duration, drogEndTime)
                const start = DT.d2t(startTime)
                const end = DT.d2t(endTime)
                addSub(
                    index,
                    newSub({
                        start,
                        end,
                        text: t("SUB_TEXT"),
                    }),
                )
            }
        }
        setIsDroging(false)
        setDrogStartTime(0)
        setDrogEndTime(0)
    }, [isDroging, drogStartTime, drogEndTime, subtitle, addSub, newSub, t, render.duration, player.duration])

    useEffect(() => {
        document.addEventListener("mouseup", onDocumentMouseUp)
        return () => document.removeEventListener("mouseup", onDocumentMouseUp)
    }, [onDocumentMouseUp])

	const left = (drogStartTime - render.beginTime) * (bounds.width / render.duration)
	const width = (drogEndTime - drogStartTime) * (bounds.width / render.duration)
    return (
        <div className="subplayer-metronomme" onMouseDown={onMouseDown} onMouseMove={onMouseMove}>
            {player && !playing && drogStartTime && drogEndTime && drogEndTime > drogStartTime ? (
                <div
                    className="template"
                    style={{ left, width }}
                ></div>
            ) : null}
        </div>
    )
}
