import * as types from "../redux-constants/neurowideDashboardConstants"

export const IS_MODAL = (isModal) => ({
    type: types.IS_MODAL,
    payload: {
        isModal
    }
})

export const NW_DASHBOARD_IS_CONFIRMATION_MODAL = (isConfirmationModal) => ({
    type: types.NW_DASHBOARD_IS_CONFIRMATION_MODAL,
    payload: {
        isConfirmationModal
    }
})