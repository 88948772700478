import {connect} from "react-redux"
import {TEAMS_IS_CONFIRMATION_MODAL} from "../../redux/actions/teamsActions"
import teamManagerIcon from "../../assets/icon/team-manager.svg"
import trashIcon from "../../assets/icon/trash.svg"
import ProfilePicture from "../profile-picture/ProfilePicture"
import {useTranslation} from "react-i18next"

function TeamUserCard({user, TEAMS_IS_CONFIRMATION_MODAL, idAdmin}) {
    const {t} = useTranslation()

    const deleteUser = () => {
        TEAMS_IS_CONFIRMATION_MODAL({modal: true, id: user.id, action: "delete"})
    }

    return (
        <>
        <div className="team-user-card">
            <ProfilePicture idUser={user.id}/>
            <p title={user.email}>{user.email}</p>
            {user.id === idAdmin && <img className="team-manager-icon" src={teamManagerIcon} alt="" title={user.id === idAdmin && t("Team manager")}/>}
            <img className="trash-btn" src={trashIcon} alt="" onClick={deleteUser} title={t("Delete user")}/>
        </div>
        </>
    )
}

export default connect(null, {TEAMS_IS_CONFIRMATION_MODAL})(TeamUserCard)