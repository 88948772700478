import DT from "duration-time-conversion"
import React, {useState, useEffect, useCallback, useRef, createRef, memo} from "react"
import {connect} from "react-redux"
import WFPlayer from "wfplayer"
import clamp from "lodash/clamp"
import debounce from "lodash/debounce"
import throttle from "lodash/throttle"
import {globalSelector} from "../../../redux/selectors/globalSelector"
import {getKeyCode} from "../../utils"
import Timeline from "../timelines/Timeline"
import Metronome from "../metronome/Metronome"
import playActionIcon from "../../../assets/icon/play-action.svg"
import pauseActionIcon from "../../../assets/icon/pause-action.svg"
import forwardIcon from "../../../assets/icon/forward.svg"
import rewindIcon from "../../../assets/icon/rewind.svg"

function secondToTime(second) {
    const add0 = (num) => (num < 10 ? `0${num}` : String(num));
    const hour = Math.floor(second / 3600);
    const min = Math.floor((second - hour * 3600) / 60);
    const sec = Math.floor(second - hour * 3600 - min * 60);
    return [hour, min, sec].map(add0).join(':');
}

const Ruler = ({ render, player, waveform}) => {
	const $ruler = createRef()
	const [width, setWidth ] = useState(0)
	useEffect(() => {
		if (!$ruler.current || !waveform) return;
		const { beginTime } = render;
		const { gridGap, gridNum, density } = waveform.drawer.config
		const { rulerColor, pixelRatio, padding } = waveform.options
		setWidth(waveform.drawer.config.width)
		const fontSize = 10;
		const fontHeight = 13;
		const ctx = $ruler.current.getContext('2d')
		ctx.clearRect(0, 0, waveform.drawer.config.width, 30);
		ctx.font = `${fontSize * pixelRatio}px Poppins`;
		ctx.fillStyle = rulerColor;
		ctx.clearRect(0, 0, pixelRatio, fontHeight * pixelRatio);
		let second = -1;
		for (let index = 0; index < gridNum + 10; index += 1) {
			const x = gridGap * index - (player.currentTime - parseInt(player.currentTime, 10)) * gridGap * 10;
			if ((index - padding) % 10 === 0) {
				second += 1;
				ctx.fillRect(x, 0, pixelRatio, fontHeight * pixelRatio);
				const time = Math.floor(beginTime + second);
				if (time % density === 0 && time >= 0) {
					ctx.fillText(secondToTime(time), x + 3 * pixelRatio, 10 * pixelRatio );
				}
			} else if ((index - padding) % 5 === 0) {
				ctx.fillRect(x, 0, pixelRatio, (fontHeight / 2) * pixelRatio);
			}
		}
	}, [$ruler,player, render , waveform]);

	return <div className="ruler"><canvas width={width} height={30} ref={$ruler}/></div>
}

const Waveform = memo(({player, audioUrl, setWaveform, setRender}) => {
        const $waveform = createRef()

        useEffect(() => {
            [...WFPlayer.instances].forEach((item) => item.destroy())

            const waveform = new WFPlayer({
                scrollable: true,
                useWorker: false,
                duration: 10,
                padding: 1,
                wave: true,
				ruler: false,
				grid:false,
                pixelRatio: 2,
				waveScale: 4.0,
                container: $waveform.current,
                mediaElement: player,
                backgroundColor: "rgba(0, 0, 0, 0)",
                waveColor: "rgba(255, 255, 255, 0.2)",
                progressColor: "rgba(255, 255, 255, 0.5)",
                gridColor: "rgba(255, 255, 255, 0.05)",
                rulerColor: "rgba(255, 255, 255, 0.5)",
				cursorColor: "rgba(255, 255, 255, 0)",
                paddingColor: "rgba(0, 0, 0, 0)",
            })

            setWaveform(waveform)
            waveform.on("update", setRender)
            if (audioUrl) waveform.load(audioUrl)
			player.addEventListener("canplay", () => setTimeout(() => waveform.seek(0), 50), { once: true })
        }, [player, audioUrl, $waveform, setWaveform, setRender])

        return <div className="waveform" ref={$waveform}/>
    }, () => true,
)

const Progress = (props) => {
    const [grabbing, setGrabbing] = useState(false)

    const onProgressClick = useCallback((event) => {
        if (event.button !== 0) return
        const currentTime = ((event.pageX - props.bounds.x) / props.bounds.width) * props.player.duration
        props.player.currentTime = currentTime
    }, [props])

    const onGrabDown = useCallback((event) => {
        if (event.button !== 0) return
        setGrabbing(true)
    }, [setGrabbing])

    const onGrabMove = useCallback((event) => {
        if (grabbing) {
            const currentTime = ((event.pageX - props.bounds.x) / props.bounds.width) * props.player.duration
            props.player.currentTime = currentTime
        }
    }, [grabbing, props.player, props.bounds])

    const onDocumentMouseUp = useCallback(() => {
        if (grabbing) {
            setGrabbing(false)
        }
    }, [grabbing])

    useEffect(() => {
        document.addEventListener("mouseup", onDocumentMouseUp)
        document.addEventListener("mousemove", onGrabMove)
        return () => {
            document.removeEventListener("mouseup", onDocumentMouseUp)
            document.removeEventListener("mousemove", onGrabMove)
        }
    }, [onDocumentMouseUp, onGrabMove])

    return (
        <div className="progress" onClick={onProgressClick}>
            <div className="bar" style={{width: `${(props.currentTime / props.player.duration) * 100}%`}}>
                <div className="handle" onMouseDown={onGrabDown}></div>
            </div>
        </div>
    )
}

const Actions = (props) => {
	const [isStart, setIsStart] = useState(true)
	const [isEnd, setIsEnd] = useState(false)

    const getDuration = useCallback((time) => {
        time = time === Infinity ? 0 : time
        return DT.d2t(time).split(".")[0]
    }, [])

    const onClickPlay = useCallback(() => {
        props.setPlaying(true)
        props.player.play()
    }, [])

    const onClickPause = useCallback(() => {
        props.setPlaying(false)
        props.player.pause()
    }, [])

	const onClickRewind = useCallback(() => {
		if(props.player.currentTime === 0) return;
		props.setGoRewind(true);
		setTimeout(() => props.setGoRewind(false), 1000);
        props.player.currentTime = Math.max(props.player.currentTime - 1, 0)
    }, [props.player])

	const onClickForward = useCallback(() => {
		if(props.player.currentTime === props.player.duration) return;
		props.setGoForward(true);
		setTimeout(() => props.setGoForward(false), 1000);
        props.player.currentTime = Math.min(props.player.currentTime + 1, props.player.duration)
    }, [props.player])

	const onKeyDown = useCallback(
		(event) => {
			const keyCode = getKeyCode(event)
			switch (keyCode) {
				case 37:
					onClickRewind();
					break;
				case 39:
					onClickForward();
					break;
				default:
					break
			}
		},
		[props.player],
	)

	useEffect(() => {
		setIsStart(props.player.currentTime === 0)
		setIsEnd(props.player.currentTime === props.duration)
    }, [props.player.currentTime, props.duration])

	useEffect(() => {
		window.addEventListener("keydown", onKeyDown);
		return () => {
			window.removeEventListener("keydown", onKeyDown)
		}
	}, [onKeyDown])

	return (
        <div className="subplayer-actions">
            <span className="current-timer">{getDuration(props.currentTime)}</span>
                <button className={['rwd', isStart ? 'hidden' : ''].join(" ").trim()} onClick={onClickRewind}><img src={rewindIcon} alt=""/></button>
                {!props.playing || isEnd ?
                    <button className={['play'].join(" ").trim()} onClick={onClickPlay}><img src={playActionIcon} alt=""/></button>
                    :
                    <button className={['pause'].join(" ").trim()} onClick={onClickPause}><img src={pauseActionIcon} alt=""/></button>
                }
                <button className={['fwd', isEnd ? 'hidden' : ''].join(" ").trim()} onClick={onClickForward}><img src={forwardIcon} alt=""/></button>
            <span className="total-timer">{getDuration(props.duration || 0)}</span>
        </div>
    )
}

function Footer(props) {
    const $footer = useRef(null)
    const [render, setRender] = useState({
        padding: 2,
        duration: 10,
        beginTime: -5,
    })

	const [bounds, setBounds] = useState({
		width: document.body.clientWidth,
		x : 0
	});

	const resize = useCallback(() => {
		const { width, left:x } = $footer.current.getBoundingClientRect();
		setBounds({ width, x});
	}, [$footer, setBounds])

	useEffect(() => {
		resize()
		const debouncedResize = debounce(resize, 300)
		window.addEventListener("resize", debouncedResize)
		return () => window.removeEventListener("resize", debouncedResize)
	}, [props.localState.minNavigation])

    const onWheel = useCallback((event) => {
            if (
                !props.player ||
                !props.waveform ||
                props.player.playing ||
                !$footer.current ||
                !$footer.current.contains(event.target)
            ) {
                return
            }

            const deltaY = Math.sign(event.deltaY) / 5
            const currentTime = clamp(props.player.currentTime + deltaY, 0, props.player.duration)
            props.player.currentTime = currentTime
            props.waveform.seek(currentTime)
        },
        [props.waveform, props.player, $footer],
    );

    useEffect(() => {
        const onWheelThrottle = throttle(onWheel, 100)
        window.addEventListener("wheel", onWheelThrottle)
        return () => window.removeEventListener("wheel", onWheelThrottle)
    }, [onWheel])

	useEffect(() => {
		if (props.waveform) {
			props.waveform.setOptions({ mediaElement: props.player })
			props.waveform.controller.destroy()
			props.waveform.controller.playInit()
		}
	}, [props.waveform, props.player])

    return (
        <div className="subplayer-footer" ref={$footer}>
            {props.player ? (
                <React.Fragment>
                    <Progress {...props} bounds={bounds}/>
                    <Actions {...props}/>
					<Ruler {...props} render={render}/>
					<Timeline {...props} render={render} bounds={bounds}/>
                    <Waveform {...props} setRender={setRender}/>
					<div className="cursor"></div>
                    <Metronome {...props} render={render} bounds={bounds}/>
                </React.Fragment>
            ) : null}
        </div>
    )
}

export default connect(globalSelector)(Footer)
