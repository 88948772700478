import {useEffect} from "react"
import ssoLogin from "../aws/cognito/ssoLogin"

export default function SigninBouyges() {

    useEffect(() => {
        ssoLogin("Bouygues")
    })

    return (
        <>
        <div className="VIEW SIGNIN-BOUYGES">
            {/*  */}
        </div>
        </>
    )
}