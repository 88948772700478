import {useTranslation} from "react-i18next"

export default function TeamManagementHeader() {
    const {t} = useTranslation()
    return (
        <>
         <div className="SECTION TEAM-MANAGEMENT-HEADER">
            <h1>{t("Team management")}</h1>
            <p>{t("Manage your team.")}</p>
        </div>
        </>
    )
}