import {connect} from "react-redux"
import {globalSelector} from "../../redux/selectors/globalSelector"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import {useTranslation} from "react-i18next"
import {useForm} from "react-hook-form"
import axios from "axios"
import {API_LEXICONS} from "../../constants/apiUrls"

function AddLexiconsModalForm({sessionState, IS_MODAL, REFRESH_COGNITO_TOKEN, forceUpdate}) {
    const {t} = useTranslation()
    const {register, handleSubmit, formState: {errors}} = useForm()

    const addTeamLexicon = (data) => {
        const bodyRequest = {
            name: data.lexiconName
        }
        currentAuthSession().then((response) => {
            REFRESH_COGNITO_TOKEN(response.idToken.toString(), response.idToken.payload.id_user, response.idToken.payload.id_team ? response.idToken.payload.id_team : undefined, response.idToken.payload.role)
        })
        const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
        axios.post(API_LEXICONS, bodyRequest, headersRequest)
        .then(() => {
            IS_MODAL(false)
            forceUpdate()
        })
        .catch((error) => {
            console.error("POST /lexicons: ", error)
        })
    }

    const closeModal = () => {
        IS_MODAL(false)
    }

    return (
        <>
        <form className="lexicons-modal-form" onSubmit={handleSubmit(addTeamLexicon)}>
            <div className="field">
                <input type="text" id="lexicon-name" name="lexicon-name" placeholder={t("Lexicon name")} {...register("lexiconName", {required: true})}/>
            </div>
            <div className="actions">
                <button className="cancel-btn" onClick={closeModal}>{t("Cancel")}</button>
                <button className="append-btn">{t("Add")}</button>
            </div>
        </form>
        </>
    )
}

export default connect(globalSelector, {REFRESH_COGNITO_TOKEN})(AddLexiconsModalForm)