import React from "react"
import {useTranslation} from "react-i18next"

export default function Component({loading}) {
    const {t} = useTranslation()
    return (
        <div className="subplayer-loading">
            <div className="loading-inner">
                <img src="/loading.svg" alt="loading"/>
                <div>{loading || t("LOADING")}</div>
            </div>
        </div>
    )
}