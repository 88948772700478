import axios from "axios"
import {API_LEXICONS} from "../../constants/apiUrls"
import {connect} from "react-redux"
import {sessionSelector} from "../../redux/selectors/sessionSelector"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import {LEXICONS_IS_CONFIRMATION_MODAL} from "../../redux/actions/lexiconsActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import {useTranslation} from "react-i18next"
import shieldIcon from "../../assets/icon/shield.svg"
import trashIcon from "../../assets/icon/trash.svg"

function LexiconCard({sessionState, REFRESH_COGNITO_TOKEN, LEXICONS_IS_CONFIRMATION_MODAL, index, lexicon, setLexiconSelected, setSelected, teamLexicon}) {
    const {t} = useTranslation()
    const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}

    const handleClick = () => {
        currentAuthSession().then((response) => {
            REFRESH_COGNITO_TOKEN(response.idToken.toString(), response.idToken.payload.id_user, response.idToken.payload.id_team ? response.idToken.payload.id_team : undefined, response.idToken.payload.role)
        })
        setLexiconSelected(lexicon)
        axios.get(`${API_LEXICONS}/${lexicon.id}/items`, headersRequest)
        .then((response) => {
            setSelected(response.data === null ? [] : response.data)
        }).catch((error) => {
            console.error("GET /lexicon/items: ", error)
        })
    }

    const deleteLexicon = () => {
        LEXICONS_IS_CONFIRMATION_MODAL({modal: true, id: lexicon.id, action: "delete"})
    }

    return (
        <>
        <li className="lexicon-list-item">
            <div className="lexicon-card" onClick={handleClick}>
                <span>{t("Lexicon")} {index}</span>
                <p>{lexicon.name}</p>
                {teamLexicon && <img className="shield-icon" src={shieldIcon} alt="" title={teamLexicon && t("Team lexicon")}/>}
            </div>
            {!teamLexicon &&
                <>
                <div className="circle" onClick={deleteLexicon}>
                    <img src={trashIcon} alt="" title={t("Delete")}/>
                </div>
                </>
            }
        </li>
        </>
    )
}

export default connect(sessionSelector, {REFRESH_COGNITO_TOKEN, LEXICONS_IS_CONFIRMATION_MODAL})(LexiconCard)