import ProfileHeader from "../sections/profile-header/ProfileHeader"
import ProfileContent from "../sections/profile-content/ProfileContent"

export default function Profile() {
    return (
        <>
        <div className="VIEW PROFILE">
            <ProfileHeader/>
            <span className="line"></span>
            <ProfileContent/>
        </div>
        </>
    )
}