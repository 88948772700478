import {connect} from "react-redux"
import {projectsSelector} from "../../redux/selectors/projectsSelector"
import {SET_TAB} from "../../redux/actions/projectsActions"
import {useTranslation} from "react-i18next"

function ProjectsTabs({projectsState, SET_TAB}) {
    const {t} = useTranslation()

    const handleClick = (tab) => {
        SET_TAB(tab)
        window.location.reload()
    }
    return (
        <>
        <div className="SECTION PROJECTS-TABS">
            <p className={`tab ${projectsState.tab === "my-projects" && "active"}`} onClick={() => handleClick("my-projects")}>{t("My projects")}</p>
            <p className={`tab ${projectsState.tab === "shared-projects" && "active"}`} onClick={() => handleClick("shared-projects")}>{t("Shared projects")}</p>
            <p className={`tab ${projectsState.tab === "recycle-bin" && "active"}`} onClick={() => handleClick("recycle-bin")}>{t("Recycle bin")}</p>
        </div>
        </>
    )
}

export default connect(projectsSelector, {SET_TAB})(ProjectsTabs)