import spinnerIcon from "../../assets/icon/spinner.svg"

export default function LoadingComponent() {
    return (
        <>
        <div className="loading-component">
            <img className="icon" src={spinnerIcon} alt=""/>
        </div>
        </>
    )
}